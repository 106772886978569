import normal from './normal';
import mute from './mute';
import vertical from './vertical';
import horizontal from './horizontal';

export default {
  normal,
  mute,
  vertical,
  horizontal
};
